import React from 'react'
import { Link } from 'react-router-dom'
import { toLocaleStringOption, toThousandsStringOption } from '../../common/common'

const StakingInfo = ({ navigate, onClickRef, onClickDocument, stakingStyles, poolInfo }) => {
    return (
        <div className={`${stakingStyles.infoWrap}`} >
            <div className={`${stakingStyles.wrap}`}>
                <div className={`${stakingStyles.leftInfo}`} >
                    <div>
                        {/* <Link to={"/"} className={`${stakingStyles.backLink}`} 
                        onClick={(e)=>{onClickDocument()}}
                        >
                            Back to list
                        </Link> */}
                        <div className={`${stakingStyles.backLink}`} 
                        style={{cursor:"pointer"}}
                        onClick={()=>navigate(-1)}
                        >
                            Back to list
                        </div>
                    </div>
                    <div className={`${stakingStyles.poolName}`}>{poolInfo.poolName}</div>
                </div>
                <div className={`${stakingStyles.rightInfo}`} >
                    <div>
                        <span className={`${stakingStyles.tit}`}>Block Height</span>
                        <span className={`${stakingStyles.height}`}>{toThousandsStringOption(`${poolInfo.nowBlockNubmer}`)}</span>
                    </div>
                    <div>
                        <span className={`${stakingStyles.tit}`} >APR</span>
                        <span className={`${stakingStyles.reward}`}>{toLocaleStringOption(poolInfo.rewardRate,2)}%</span>
                    </div>
                    <div>
                        <span className={`${stakingStyles.tit}`} >Total Staked</span>
                        <span className={`${stakingStyles.mmt}`} >{toLocaleStringOption(poolInfo.stakedBalance, 0)} MMT</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StakingInfo