import { combineReducers } from "redux";
import { all } from "@redux-saga/core/effects";
import { persistReducer } from 'redux-persist';
import storage from "redux-persist/lib/storage";

import user from "./user";
import staking from "./staking";

const persistConfig = {
    key: "Mammoth Staking",
    storage,
    whitelist: [
        "user",
    ]
}

const rootreducer = combineReducers({
    user,
    staking
});

export function* rootsaga() {

}

const persistedReducer = persistReducer(persistConfig, rootreducer);

export default persistedReducer;