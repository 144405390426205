import React from 'react';

const StakingSection = ({ stakingDocument, preDocument, PoolListComponent, mainStyles, checkedStatus, onClickCheckBox }) => {
    // h3 부터 주석 처리

    return (
        <section className={`${mainStyles.staking}`} ref={stakingDocument}>
            <h3 className={`${mainStyles.tit}`} >Staking</h3>
            <div className={`${mainStyles.switchWrap}`}>
                <input type="checkbox" id={`${mainStyles.switch}`} checked={checkedStatus} readOnly />
                <label htmlFor="switch" className={`${mainStyles.switchLabel}`} onClick={onClickCheckBox}>
                    <span className={`${mainStyles.btnOnOff}`}></span>
                </label>
            </div>
            <span className={`${mainStyles.btnOnOffStatusMessage}`}>{checkedStatus ? "Active" : "Inactive"}</span>
            <PoolListComponent />
        </section>
    )
}

export default StakingSection