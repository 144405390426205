import React, { createRef, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// import mainStyles from "../assert/css/Main.module.css"
import mainStyles from "../assert/css/Staking.module.css"
import { aprFormula, fromWei, sortNum, sortUnit, toLocaleStringOption, toThousandsStringOption, toWei } from '../common/common';
import { setPid, setStakeListInterval, setStakingLists, setTotalStakeInterval, setUserInfo } from '../redux_modules/staking';
import MainSection from '../containers/Main/MainSection';
import BenefitSection from '../containers/Main/BenefitSection';
import StakingSection from '../containers/Main/StakingSection';
import { BN } from 'bn.js';

import MMTStakingJSON from "../abi/MMTStaking.json";
import moment from 'moment';

const Main = ({ mobileCheck, windowWidth, windowHeight, setWindowHeight, preDocument, defaultProv, contract, active, onClickConnectWalletBtn, account, web3 }) => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { pathname } = useLocation();

    const [totalStake, setTotalStake] = useState("");
    const [intervalTotal, setIntervalTotal] = useState("");
    const [stakingList, setStakingList] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [checkedStatus, setCheckedStatus] = useState(true);

    const [viewPoolLists, setViewPoolLists] = useState([]);
    const [skeletonLoading, setSkeletonLoading] = useState(false);

    const totalStakeInterval = useSelector((state) => state.staking.totalStakeInterval);
    const stakeListInterval = useSelector((state) => state.staking.aprInterval);
    const stakingLists = useSelector((state) => state.staking.stakingLists);

    const [stakingSection, setStakingSection] = useState();
    const [scrollX, setScrollX] = useState(0)

    const listDocument = useRef(0);
    const mainDocument = useRef(0);
    const stakingDocument = useRef(0);



    // contract에 얼만큼 MMT가 들어가 있느냐
    const getTotalStake = async () => {
        let stakeTotalAmount = 0;
        let poolLength = await contract.methods.poolLength().call();
        for (let i = 0; i < poolLength; i++) {
            let pools = await contract.methods.pools(i).call();
            stakeTotalAmount = new BN(`${stakeTotalAmount}`).add(new BN(`${pools.stakedBalance}`)).toString();
        }
        stakeTotalAmount = fromWei(defaultProv, stakeTotalAmount);
        if (totalStake != stakeTotalAmount) {
            setSkeletonLoading(false)
            setIntervalTotal(stakeTotalAmount)
            setTimeout(() => {
                setSkeletonLoading(true)
            }, 2500)
        }
        setTotalStake(stakeTotalAmount);
    };

    // stakeList 가져오는거
    const getStakeList = async () => {
        // poolInfo, userInfo 가져오기
        let poolLength = await contract.methods.poolLength().call();
        // let poolLength = 3;
        let sendPoolData = [];
        let sendUserData = [];
        let poolInfo = {
            pid: 0,
            poolName: "",
            startRewardBlock: 0,
            endRewardBlock: 0,
            rewardMMTperBlock: 0,
            stakedBalance: 0,
            totalRewardbalance: 0,
            lastRewardBlock: 0,
            rewardRate: 0,
        };

        let userInfo = {
            amount: 0,
            rewardDebt: 0,
        };

        for (let i = 0; i < poolLength; i++) {
            let data = []

            if (active == true) {
                let staker = await contract.methods.Stakers(i, account).call();
                //userInfo 가져오기
                userInfo = {
                    amount: web3 != undefined ? fromWei(web3, staker.amount) : "0",
                    rewardDebt: staker.rewardDebt,
                };
            }
            //poolInfo 가져오기
            let pools = await contract.methods.pools(i).call();

            // 현재 블록
            let blockuNmber = await contract.methods.BlockNumber().call();
            // 현재 블록에 대한 타임 스탬프
            let { timestamp } = await defaultProv.eth.getBlock(blockuNmber);

            // startBlock
            let startBlockSubBlock = new BN(`${pools.startRewardBlock}`).sub(new BN(`${blockuNmber}`)).toString();
            let startBlockSubTimeStamp;
            let startRewardTimeStamp;


            if (Number(startBlockSubBlock) >= 0) {
                startBlockSubTimeStamp = new BN(`${Math.abs(startBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
                startRewardTimeStamp = new BN(`${timestamp}`).add(new BN(`${startBlockSubTimeStamp}`)).toString();
            } else {
                startBlockSubTimeStamp = new BN(`${Math.abs(startBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
                startRewardTimeStamp = new BN(`${timestamp}`).sub(new BN(`${startBlockSubTimeStamp}`)).toString();
            }

            //  endBlock
            let endBlockSubBlock = new BN(`${pools.endRewardBlock}`).sub(new BN(`${pools.startRewardBlock}`)).toString();
            let endBlockSubTimeStamp;
            let endRewardTimeStamp;

            if (Number(endBlockSubBlock) >= 0) {
                endBlockSubTimeStamp = new BN(`${Math.abs(endBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
                endRewardTimeStamp = new BN(`${startRewardTimeStamp}`).add(new BN(`${endBlockSubTimeStamp}`)).toString();
            } else {
                endBlockSubTimeStamp = new BN(`${Math.abs(endBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
                endRewardTimeStamp = new BN(`${startRewardTimeStamp}`).sub(new BN(`${endBlockSubTimeStamp}`)).toString();
            }

            let apr = await aprFormula(defaultProv, pools.totalRewardbalance, pools.stakedBalance);

            poolInfo = {
                pid: i,
                poolName: pools.poolName,
                startRewardBlock: pools.startRewardBlock,
                endRewardBlock: pools.startRewardBlock,
                rewardMMTperBlock: pools.rewardMMTperBlock,
                stakedBalance: pools.stakedBalance,
                totalRewardbalance: pools.totalRewardbalance,
                lastRewardBlock: pools.lastRewardBlock,
                rewardRate: apr,
                commission: pools.commission,
                startRewardTimeStamp: startRewardTimeStamp,
                endRewardTimeStamp: endRewardTimeStamp,
            };

            sendPoolData.push(poolInfo);
            sendUserData.push(userInfo);
        }
        setUserInfo(sendUserData)
        setStakingList(sendPoolData)
        // dispatch(setStakingList(sendPoolData));
    };


    const getNewDate = (timestamp) => {
        let date = new Date(timestamp * 1000);
        let data = moment(date).format("YY.MM.DD");

        return data
    }

    const setAprInterval = async () => {
        let poolLength = await contract.methods.poolLength().call();
        let sendPoolData = [];
        for (let i = 0; i < poolLength; i++) {
            let pools = await contract.methods.pools(i).call();

            // 현재 블록
            let blockuNmber = await contract.methods.BlockNumber().call();
            // 현재 블록에 대한 타임 스탬프
            let { timestamp } = await defaultProv.eth.getBlock(blockuNmber);

            // startBlock
            let startBlockSubBlock = new BN(`${pools.startRewardBlock}`).sub(new BN(`${blockuNmber}`)).toString();
            let startBlockSubTimeStamp;
            let startRewardTimeStamp;


            if (Number(startBlockSubBlock) >= 0) {
                startBlockSubTimeStamp = new BN(`${Math.abs(startBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
                startRewardTimeStamp = new BN(`${timestamp}`).add(new BN(`${startBlockSubTimeStamp}`)).toString();
            } else {
                startBlockSubTimeStamp = new BN(`${Math.abs(startBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
                startRewardTimeStamp = new BN(`${timestamp}`).sub(new BN(`${startBlockSubTimeStamp}`)).toString();
            }

            //  endBlock
            let endBlockSubBlock = new BN(`${pools.endRewardBlock}`).sub(new BN(`${pools.startRewardBlock}`)).toString();
            let endBlockSubTimeStamp;
            let endRewardTimeStamp;

            if (Number(endBlockSubBlock) >= 0) {
                endBlockSubTimeStamp = new BN(`${Math.abs(endBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
                endRewardTimeStamp = new BN(`${startRewardTimeStamp}`).add(new BN(`${endBlockSubTimeStamp}`)).toString();
            } else {
                endBlockSubTimeStamp = new BN(`${Math.abs(endBlockSubBlock)}`).mul(new BN(`${3}`)).toString();
                endRewardTimeStamp = new BN(`${startRewardTimeStamp}`).sub(new BN(`${endBlockSubTimeStamp}`)).toString();
            }


            let apr = await aprFormula(defaultProv, pools.totalRewardbalance, pools.stakedBalance);

            let poolInfo = {
                pid: i,
                poolName: pools.poolName,
                startRewardBlock: pools.startRewardBlock,
                endRewardBlock: pools.startRewardBlock,
                rewardMMTperBlock: pools.rewardMMTperBlock,
                stakedBalance: pools.stakedBalance,
                totalRewardbalance: pools.totalRewardbalance,
                lastRewardBlock: pools.lastRewardBlock,
                rewardRate: apr,
                commission: pools.commission,
                startRewardTimeStamp: startRewardTimeStamp,
                endRewardTimeStamp: endRewardTimeStamp,
            };
            sendPoolData.push(poolInfo);
        }
        setStakingList(sendPoolData)
        // dispatch(setStakingList(sendPoolData));
    }


    const setViewPoolList = () => {
        if (stakingList != undefined) {
            let nowTimeStamp = Math.floor(new Date() / 1000);
            let returnList = [];
            for (let i = 0; i < stakingList.length; i++) {
                let endTimeStamp = Number(stakingList[i].endRewardTimeStamp);

                if (checkedStatus) {
                    if (nowTimeStamp <= endTimeStamp) {
                        returnList.push(stakingList[i]);
                    }
                } else {
                    if (nowTimeStamp >= endTimeStamp) {
                        returnList.push(stakingList[i]);
                    }
                }
            }
            setViewPoolLists(returnList)
        }
    }

    // useEffect(() => {
    useLayoutEffect(() => {
        setViewPoolList();
    }, [stakingList, checkedStatus])

    useEffect(() => {
        if (contract != undefined) {
            getTotalStake();
        }
    }, [contract]);

    useEffect(() => {
        if (contract != undefined) {
            getStakeList();
        }
    }, [contract, active, web3, defaultProv]);

    useEffect(() => {
        // totalStakeInterval
        let letInterval = "";
        let nowUrls = window.location.href.split('/');
        if (totalStakeInterval == "" && contract != undefined && totalStake != "") {
            letInterval = setInterval(() => {
                let url = window.location.href.split('/');
                getTotalStake();
                if (JSON.stringify(nowUrls) != JSON.stringify(url)) {
                    clearInterval(letInterval);
                    dispatch(setTotalStakeInterval(""))
                    setTotalStake("");
                }
            }, [5000]);

            dispatch(setTotalStakeInterval(letInterval));

        } else if (pathname != "/" && totalStakeInterval != "") {
            // clearInterval(totalStakeInterval);
            // dispatch(setTotalStakeInterval(""))
            // setTotalStake("");
        } 
    }, [pathname, contract, web3, account, totalStakeInterval, totalStake]);



    // useEffect(() => {
    useLayoutEffect(() => {
        // stakeListInterval
        let letInterval = "";
        let nowUrls = window.location.href.split('/');
        if (stakeListInterval == "" && contract != undefined && stakingList.length != 0) {
            letInterval = setInterval(() => {
                let url = window.location.href.split('/');
                setAprInterval();

                if (JSON.stringify(nowUrls) != JSON.stringify(url)) {
                    clearInterval(letInterval);
                    dispatch(setStakeListInterval(""));
                    setStakingList("")
                    // dispatch(setStakingList(""));
                }
            // }, [3000]);
            }, [3000]);

            dispatch(setStakeListInterval(letInterval));

        } else if (pathname != "/" && stakeListInterval != "") {
            // clearInterval(stakeListInterval);
            // dispatch(setStakeListInterval(""))
            // setStakingList("");
        }
    }, [pathname, contract, stakeListInterval, stakingList]);

    // window.addEventListener("scroll", () => {
    //     if (mobileCheck == false) {
    //         if (document.documentElement.scrollTop >= 800) {
    //             setSkeletonLoading(false)
    //         }else if(document.documentElement.scrollTop < 800){
    //             setTimeout(() => {
    //                 setSkeletonLoading(true)
    //             }, 2500)
    //         }
    //     } else if (mobileCheck == true) {
    //         if (document.documentElement.scrollTop >= 400) {
    //             setSkeletonLoading(false)
    //         }else if(document.documentElement.scrollTop < 400){
    //             setTimeout(() => {
    //                 setSkeletonLoading(true)
    //             }, 2500)
    //         }
    //     }
    // });

    const PoolListConponentList = () => {
        return (
            viewPoolLists.map((item, index) => (
                <div className={`${mainStyles.tbody} ${mainStyles.flex}`} key={index}>
                    <div className={`${mainStyles.name}`} >
                        <img src={require(`../assert/img/staking/sym_bnb@2x.png`)} width={"40"} alt="bnb" />
                        {/* <img src={`${item.poolName}.png`} width={"40"} alt={`${item.poolName}`} /> */}
                        <div>
                            <p>{item.poolName}</p>
                            <p className={`${mainStyles.startEnd}`}>Starts {getNewDate(item.startRewardTimeStamp)}, Ends {getNewDate(item.endRewardTimeStamp)}</p>
                        </div>
                    </div>
                    <div className={`${mainStyles.reward}`} >{checkedStatus ? toLocaleStringOption(item.rewardRate, 2) : "0"}%</div>
                    <div className={`${mainStyles.tvl}`} >{toLocaleStringOption(sortNum(fromWei(defaultProv, item.stakedBalance)), 0)}{sortUnit(fromWei(defaultProv, item.stakedBalance))} MMT</div>
                    <div className={`${mainStyles.mmt}`} >{toLocaleStringOption(sortNum(userInfo[index].amount), 0)}{sortUnit(userInfo[index].amount)} MMT</div>
                    <div className={`${mainStyles.commission}`}>{item.commission}%</div>
                    <div className={`${mainStyles.btn}`} >
                        <Link to={`/staking/${item.pid}`} onClick={onClickDocument}>
                            <button type="button" className={`${mainStyles.btnStaking}`} >
                                {/* <Link to={`/staking/${item.pid}`} onClick={()=>{onClickSetStaking(item)}}> */}
                                Staking
                            </button>
                        </Link>
                    </div>
                </div>
            ))
        )
    }

    const PoolListComponent = () => {
        return (
            viewPoolLists == "" ?
                <div className={`${mainStyles.noContent}`} >
                    <img src={require("../assert/img/ico_content_no@2x.png")} width="70" />
                    <p>No Content</p>
                </div>
                :
                <div className={`${mainStyles.tableXscroll}`} ref={listDocument}>
                    <div className={`${mainStyles.stakingTable}`} >
                        <div className={`${mainStyles.thead} ${mainStyles.flex}`} >
                            <div className={`${mainStyles.name}`} >Pool name</div>
                            <div className={`${mainStyles.reward}`} >APR</div>
                            <div className={`${mainStyles.tvl}`}>TVL</div>
                            <div className={`${mainStyles.mmt}`} >My staked assets</div>
                            <div className={`${mainStyles.commission}`}>Commission</div>
                            <div className={`${mainStyles.btn}`} ></div>
                        </div>
                        <PoolListConponentList />
                    </div>
                </div>
        )
    }

    const onClickCheckBox = () => {
        setCheckedStatus(!checkedStatus)
    };

    useEffect(() => {
        window.localStorage.setItem("MammothStaking_scroll", 0);
    }, [])

    const saveScrollX = useCallback(() => {
        let left = document.querySelector(`.${listDocument.current.className}`).scrollLeft;
        window.localStorage.setItem("MammothStaking_scroll", left);
    },[])

    useEffect(() => {
        if (listDocument.current != undefined) {
            document.querySelector(`.${listDocument.current.className}`).addEventListener("scroll", saveScrollX);
        }
    }, [viewPoolLists, listDocument]);

    useEffect(() => {
        if (listDocument.current != undefined) {
            let scroll = window.localStorage.getItem("MammothStaking_scroll");
            document.querySelector(`.${listDocument.current.className}`).scrollTo(Number(scroll), 0)
        }
    }, [viewPoolLists, listDocument]);


    useEffect(() => {
        // useLayoutEffect(() => {
        let yScroll = window.localStorage.getItem("MammothStaking_StakingScroll");
        if (yScroll == null) {
            setWindowHeight(0);
            return window.localStorage.setItem("MammothStaking_StakingScroll", 0)
        } else {
            setWindowHeight(yScroll);
            window.localStorage.setItem("MammothStaking_StakingScroll", yScroll);
        }
    }, [pathname, windowHeight]);

    const onClickDocument = useCallback(() => {
        let yScroll = document.querySelector(`.${stakingDocument.current.className}`).offsetTop;
        window.localStorage.setItem("MammothStaking_StakingScroll", yScroll);
    }, [])

    // useEffect(() => {
    useLayoutEffect(() => {
        if (pathname == "/" && viewPoolLists != "" && windowHeight != 0) {
            let height = windowHeight - 60;
            // let height = windowHeight;
            window.scrollTo(0, height)
            setWindowHeight(0);
            window.localStorage.setItem("MammothStaking_StakingScroll", 0);
        }
    }, [pathname, viewPoolLists, windowHeight]);


    return (
        <div className={`${mainStyles.container}`} ref={mainDocument}>
            <MainSection mainStyles={mainStyles} totalStake={totalStake} skeletonLoading={skeletonLoading} />
            <BenefitSection mainStyles={mainStyles} />
            <StakingSection stakingDocument={stakingDocument} PoolListComponent={PoolListComponent} mainStyles={mainStyles} preDocument={preDocument} checkedStatus={checkedStatus} onClickCheckBox={onClickCheckBox} />
        </div>
    )
}

export default Main