import React from 'react'
import { toLocaleStringOption } from '../../common/common'

const MainSection = ({ mainStyles, totalStake, skeletonLoading }) => {
    {/* overviewWrap 부분 주석 처리 하기 */ }
    return (
        <section className={`${mainStyles.main}`} >
            <h2 className={`${mainStyles.tit}`}>
                MMT Staking<br />
                makes happy things on your assets
            </h2>
            <p className={`${mainStyles.exp}`}>
            <span>If you have stake MMT,</span> 
                    <span>that will continue to bring you</span> 
                    <span>more MMT and<br /> good luck</span> 
                    even when you are doing other things.
            </p>
            <div className={`${mainStyles.psr}`} >
                <img src={require(`../../assert/img/img_mainvisual.png`)} alt="메인 이미지" />
                <div className={`${mainStyles.overviewWrap}`}
                // style={{display:"none"}}
                >
                    <div className={`${mainStyles.tit}`} >Overview</div>
                    <div className={`${mainStyles.totalWrap}`} >
                        <div className={`${mainStyles.tit}`} >Total Stake</div>
                        <div className={`${mainStyles.cont}`} >{toLocaleStringOption(totalStake, 0)} MMT</div>
                        {
                            skeletonLoading ? "" :
                                <div className={`${mainStyles.skeletonLoading}`} ></div>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MainSection